import React from 'react';
import { action, IReactionDisposer, reaction } from 'mobx';
import { inject, observer } from 'mobx-react';
import { SingletonRouter, withRouter } from 'next/router';

import { Instance } from 'mobx-state-tree';
import { IReactComponent } from 'mobx-react/dist/types/IReactComponent';
import config from '../helpers/config';

import { Session } from '../stores/session';
import { LEGACY_APPLICATION } from '../stores/application';
import { I18nUtils } from '../stores/i18nUtils';

const { publicRuntimeConfig } = config;
const { HTTP_HOST } = publicRuntimeConfig;

interface WithRedirectToBookingsProps {
  i18nUtils: Instance<typeof I18nUtils>;
  lng: string;
  router?: SingletonRouter;
  session: Instance<typeof Session>;
  t: (param: string) => string;
  tracker: {
    identify: Function;
  };
}

export const withRedirectToBookings = (Component: any): IReactComponent => {
  @(withRouter as any)
  @inject(({ store: { cities, i18nUtils, session, tracker } }) => ({
    cities,
    i18nUtils,
    session,
    tracker,
  }))
  @observer
  class WithRedirectToBookings extends React.Component<WithRedirectToBookingsProps, null> {
    listeners: IReactionDisposer[];

    static async getInitialProps(
      { lng, req },
      { articles, cities, cmsContent },
    ): Promise<WithRedirectToBookingsProps> {
      // Check if Page has a `getInitialProps`; if so, call it.
      const pageProps =
        Component.getInitialProps &&
        (await Component.getInitialProps({ lng, req }, { articles, cities, cmsContent }));
      // Return props.
      return { ...pageProps };
    }

    componentDidMount(): void {
      const { session } = this.props;

      /* eslint-disable react/destructuring-assignment */
      this.listeners = [
        reaction(
          () => this.props.session.user,
          () => {
            const { worker } = session;

            if (worker && worker.id) {
              this.redirectUser();
            }
          },
          {
            name: 'Check redirects from session user',
            fireImmediately: true,
          },
        ),
      ];
      /* eslint-enable react/destructuring-assignment */
    }

    componentWillUnmount(): void {
      while (this.listeners.length) {
        this.listeners.pop()();
      }
    }

    // TODO Move this logic into some state transactional - it lives in login too so consolidate
    @action('redirectUser - withRedirectToBookings')
    redirectUser(): void {
      const {
        i18nUtils,
        session: {
          hasSignedLatestAgreement,
          hasVerifiedEmail,
          worker,
          worker: { application } = { application: {} },
        },
        router,
      } = this.props;

      const {
        DECLINED,
        IN_REVIEW,
        INTERVIEW,
        ON_BOARDING,
        PENDING,
        SUBMITTED,
        SUCCESSFUL,
      } = LEGACY_APPLICATION;

      switch (true) {
        case worker.operatorPartner_id && !worker.verifiedEmail:
          if (router.pathname !== '/sign-agreement') {
            router.push(`/${i18nUtils.locale}/sign-agreement`);
          }
          break;
        case worker.active ||
          (application &&
            application.status === SUCCESSFUL &&
            hasVerifiedEmail &&
            hasSignedLatestAgreement):
          if (router.pathname !== '/bookings') {
            router.push(`/${i18nUtils.locale}/bookings`);
          }
          break;
        case application && application.status === DECLINED:
          if (router.pathname !== '/application-declined') {
            router.push(`/${i18nUtils.locale}/application-declined`);
          }
          break;
        case application && application.status === PENDING:
          if (router.pathname !== '/submit-application') {
            router.push(`/${i18nUtils.locale}/submit-application`);
          }
          break;
        case application.status !== SUCCESSFUL &&
          application.status !== DECLINED &&
          (application.status === SUBMITTED ||
            application.status === IN_REVIEW ||
            !worker.residentialAddress ||
            !worker.name ||
            !worker.mobile ||
            !worker.residentialAddress.address1 ||
            application.status === INTERVIEW ||
            application.status === ON_BOARDING):
          if (router.pathname !== '/almost-there') {
            window.location.href = `https://${HTTP_HOST}/almost-there`;
          }
          break;
        case !worker.signedLatestAgreement || !worker.verifiedEmail:
          if (router.pathname !== '/sign-agreement') {
            router.push(`/${i18nUtils.locale}/sign-agreement`);
          }
          break;
        default:
          if (router.pathname !== '/bookings') {
            router.push(`/${i18nUtils.locale}/bookings`);
          }
          break;
      }
    }

    render(): JSX.Element {
      return <Component {...this.props} />;
    }
  }

  return observer(WithRedirectToBookings);
};
